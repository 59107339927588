import { Context, createContext, ReactNode, useContext, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetMe } from "../hooks/useAuth";
import { getCookies, removeCookies } from "../utils/cookies";
import Loading from "../components/atoms/loading/Loading";
import { APP_ADMIN_TOKEN } from "../constants";

interface IAppContext {
  getMe: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, Error>>;
  logOut: () => void;
  isAuthenticated: boolean;
  user: any | null;
}

const AppContext: Context<IAppContext> = createContext<IAppContext>(
  {} as IAppContext
);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [user, setUser] = useState<any>({
    isAuthenticated: !!getCookies(APP_ADMIN_TOKEN),
    user: getCookies(APP_ADMIN_TOKEN),
  });

  const isInitial = location.pathname === "/login" || '/';

  const logOut = () => {
    setUser({
      isAuthenticated: false,
      user: null,
    });
    navigate("/login");
  };

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    refetch: getMe,
  } = useGetMe(
    (userData) => {
      setUser({
        isAuthenticated: true,
        user: userData,
      });
      isInitial ? navigate("/users") : navigate(location);
    },

    () => {
      removeCookies("techwell-token");
      navigate("/login");
    }
  );

  if (error) {
    return <>{error.message}</>;
  }

  if (isLoading) {
    return <Loading/>
  }

  return (
    <AppContext.Provider value={{ ...user, getMe, logOut }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
