import React, { FC, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookies } from "../utils/cookies";
import { APP_ADMIN_TOKEN } from "../constants";
import HeaderDrawer from "../components/templates/headerDrawer/HeaderDrawer";
import Snackbar from "../components/molecules/snackbar";


interface IAuthLayout {
  children: React.ReactNode;
}

const AuthLayout: FC<IAuthLayout> = ({ children }) => {
  const navigate = useNavigate();
  const token = getCookies(APP_ADMIN_TOKEN);


  useLayoutEffect(() => {
    !token && navigate("/login");
  }, [navigate, token]);



  return (
    <Snackbar>
      <HeaderDrawer children={children} />
    </Snackbar>
  );
};

export default AuthLayout;
